<template>

  <b-form @submit.stop.prevent="formOnSubmit">
    <div class="row align-items-center">
      <div class="col">
        <!-- doctor commission -->
        <b-form-group
          id="input-group-doctor-commission"
          :label="`Komisi Dokter (${isPercent ? '%' : 'Rp'}):`"
          label-for="input-doctor-commission"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                squared
                @click="btnIsPercentToggle"
                class="bg-light-primary font-weight-bold text-primary"
              >{{ isPercent ? '%' : 'Rp' }}</b-input-group-text>
            </template>
            <b-form-input
              id="input-doctor-commission"
              :readonly="formatedPrice == 0"
              @keyup="doctorCommisionOnKeyUp"
              v-model="formatedDoctorCommission"
              placeholder="Komisi Dokter..."
            ></b-form-input>
          </b-input-group>
          <small class="text-danger">{{ error.doctor_commission }}</small>
        </b-form-group>

        <!-- hospital income -->
        <b-form-group
          id="input-group-hospital-income"
          :label="`Pendapatan Rumah Sakit (${isPercent ? '%' : 'Rp'}):`"
          label-for="input-hospital-income"
        >
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                squared
                @click="btnIsPercentToggle"
                class="bg-light-primary font-weight-bold text-primary"
              >{{ isPercent ? '%' : 'Rp' }}</b-input-group-text>
            </template>
            <b-form-input
              id="input-hospital-income"
              :readonly="formatedPrice == 0"
              @keyup="hospitalIncomeOnKeyUp"
              v-model="formatedHospitalIncome"
              placeholder="Pendapatan Rumah Sakit..."
            ></b-form-input>
          </b-input-group>
          <small class="text-danger">{{ error.hospital_income }}</small>
        </b-form-group>
      </div>
      <div class="col">
        <!-- Input Doctor -->
        <b-form-group
          id="input-group-doctor"
          label="Dokter:"
          label-for="input-doctor"
        >
          <treeselect
            v-model="mutated_form_doctors_id"
            placeholder="Pilih Dokter"
            :maxHeight="100"
            alwaysOpen
            :multiple="true"
            :options="doctors"
            @select="doctorOnSelect"
            @deselect="doctorOnDeselect"
          />
        </b-form-group>

        <!-- Selected Doctors Table -->
        <div style="min-height: 150px; max:height: 500px; margin-top: 100px; overflow-y: auto">
          <small class="text-danger">{{ error.doctors_id }}</small>
          <b-table
            striped
            hover
            class="mt-3"
            :items="doctors_id"
            :fields="fields"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'actions' ? '10%' : '' }"
              />
            </template>
            <template #cell(actions)='data'>
              <b-button
                size="sm"
                class="btn-danger"
                v-b-tooltip.hover
                title="Hapus"
                @click="btnRemoveDoctorOnClick(data.item.id)"
              ><i class="fas fa-trash px-0"></i></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <!-- Submit & Cancel button -->
    <b-button
      type="submit"
      variant="primary"
    >Simpan</b-button>
    <b-button
      type="button"
      class="ml-2"
      variant="default"
      @click="$router.push('/payment-category/list')"
    >
      Batal
    </b-button>
  </b-form>

</template>

<script>

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'

export default {

  props: {
    form: Object,
    doctors_id: Array,
  },

  components: {
    Treeselect
  },

  data() {
    return {
      // Fields
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "name",
          label: "Nama Dokter",
          sortable: true,
        },
        { key: 'actions', label: 'Aksi' }
      ],
      // Options
      doctors: [],
      // Error
      error: {
        payment_category_id: '',
        doctors_id: '',
        hospital_income: '',
        doctor_commission: '',
      },
      // Mutated
      mutated_form_doctors_id: this.form.doctors_id,
      // Other
      number: 1,
      totalPercentage: 100,
      isPercent: false
    }
  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString('id-ID')
        } else {
          this.form.price = ''
        }
      },
      get() {
        return this.form.price
      }
    },

    formatedDoctorCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.doctor_commission = newValue.toLocaleString('id-ID')
        } else {
          this.form.doctor_commission = ''
        }
      },
      get() {
        return this.form.doctor_commission
      }
    },

    formatedHospitalIncome: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.hospital_income = newValue.toLocaleString('id-ID')
        } else {
          this.form.hospital_income = ''
        }
      },
      get() {
        return this.form.hospital_income
      }
    }
  },

  methods: {

    async getDoctorOptions() {
      let response = await module.setDoctorTreeSelect(`doctors-doesnt-have-payment-category-commission/${this.$route.params.id}`)
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
      }
    },

    async formOnSubmit() {
      if (!this.isPercent) {
        let intPrice = parseInt(this.form.price.replace(/\D/g, ''), 10)
        let intDoctorCommission = parseInt(this.form.doctor_commission.replace(/\D/g, ''), 10)
        let intHospitalIncome = parseInt(this.form.hospital_income.replace(/\D/g, ''), 10)
        if (intPrice < intDoctorCommission || intPrice < intHospitalIncome) {
          Swal.fire('Gagal', 'Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi Harga', 'warning')
        } else {
          this.actualFormSubmit()
        }
      } else {
        this.actualFormSubmit()
      }
    },

    async actualFormSubmit() {
      this.form['is_percent'] = this.isPercent
      this.form.doctors_id = this.mutated_form_doctors_id
      // Make Request
      let response = await module.submit(this.form, 'payment-category-x-doctor')
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        this.$router.push('/payment-category/list')
      }
    },

    btnIsPercentToggle() {
      this.error.doctor_commission = ''
      this.error.hospital_income = ''
      this.totalPercentage = 100
      this.form.doctor_commission = ''
      this.form.hospital_income = ''
      this.isPercent ? this.isPercent = false : this.isPercent = true
    },

    doctorCommisionOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100
          this.totalPercentage -= this.form.doctor_commission
          this.form.hospital_income = this.totalPercentage
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ''), 10)
          share -= parseInt(this.form.doctor_commission.replace(/\D/g, ''), 10)
          this.form.hospital_income = !isNaN(share) ? share.toLocaleString('id-ID') : ''
        }
      }
    },

    hospitalIncomeOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100
          this.totalPercentage -= this.form.hospital_income
          this.form.doctor_commission = this.totalPercentage
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ''), 10)
          share -= parseInt(this.form.hospital_income.replace(/\D/g, ''), 10)
          this.form.doctor_commission = !isNaN(share) ? share.toLocaleString('id-ID') : ''
        }
      }
    },

    doctorOnSelect(evt) {
      let found = this.doctors.findIndex(x => x.id == evt.id)
      this.doctors_id.push({ number: this.number++, name: this.doctors[found].label })
    },

    doctorOnDeselect(evt) {
      let found = this.doctors_id.findIndex(x => x.id == evt.id)
      let index = this.doctors_id.indexOf(this.doctors_id[found])
      this.doctors_id.splice(index, 1)
    },

    btnRemoveDoctorOnClick(id) {
      let found = this.doctors_id.findIndex(x => x.id == id)
      let index = this.doctors_id.indexOf(this.doctors_id[found])
      this.doctors_id.splice(index, 1)
      index = this.mutated_form_doctors_id.indexOf(id)
      this.mutated_form_doctors_id.splice(index, 1)
    },

    resetSelectedDoctor() {
      this.doctors_id = []
    }

  },

  watch: {
    mutated_form_doctors_id: function (newVal) {
      if (newVal.length == 0) this.resetSelectedDoctor()
    }
  },

  mounted() {
    this.getDoctorOptions()
  },

}
</script>

<style scoped>
.bg-light-primary:hover {
  background-color: #e1f0ffa6 !important;
}
</style>